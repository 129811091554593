import React from "react"
import { SubTitle, SubTitleColors } from "../components/sub-title"
import { Title } from "../components/title"

import * as styles from "./contact.module.scss"
import { PHONE_NUMBER } from "../consts/contact-data"

const Contact = () => (
  <section id="contact" className="contact pt-120 pb-120">
    <div className="container">
      <div className="row">
        <div className="col-md-10 offset-md-1 mb-60 text-center">
          <SubTitle color={SubTitleColors.red}>
            Bądź z nami w kontakcie
          </SubTitle>
          <Title>Kontakt</Title>
        </div>
        <div className="col-md-10 offset-md-1">
          <div className="contact-info">
            <div className="row">
              <div className="col-md-4">
                <div className={styles.item}>
                  <div className={styles.cont}>
                    <h5>Nasz adres</h5>
                    <p>
                      3 Maja 10
                      <br />
                      35-030 Rzeszów
                      <br />
                      Polska
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className={styles.item}>
                  <div className={styles.cont}>
                    <h5>Telefon & email</h5>
                    <h6>{PHONE_NUMBER}</h6>
                    <p>texas.rzeszow@gmail.com</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className={styles.item}>
                  <div className={styles.cont}>
                    <h5>Godziny otwarcia</h5>
                    <p>
                      poniedziałek - czwartek
                      <br />
                      12:00 - 00:00
                    </p>
                    <p>
                      piątek - sobota
                      <br />
                      12:00 - 02:00
                    </p>
                    <p>
                      niedziela
                      <br />
                      12:00 - 00:00
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Contact
