import React from "react"
import * as cn from "classnames"
import * as $ from "jquery"

import OwlCarousel from "react-owl-carousel"
import "owl.carousel/dist/assets/owl.carousel.css"
import "owl.carousel/dist/assets/owl.theme.default.css"

import "../styles/owl-theme.scss"

import * as styles from "./slider.module.scss"
import * as image from "../assets/steak.jpg"
import * as image2 from "../assets/steak2.jpg"
import { ButtonAnchor } from "../components/button"

const Item = ({ background, children }) => (
  <div
    className={cn(styles.item, styles.bgImg)}
    style={{ backgroundImage: `url(${background})` }}
  >
    <div
      className={cn(
        styles.container,
        styles.vMiddle,
        styles.caption,
        "text-center caption"
      )}
    >
      {children}
    </div>
  </div>
)

const Slider = () => (
  <header id="home" className={cn(styles.header, "pos-re", styles.sliderFade)}>
    {typeof window !== "undefined" && OwlCarousel && (
      <OwlCarousel
        className="owl-theme"
        loop
        margin={0}
        items={1}
        smartSpeed={5}
        autoplay
        nav={false}
        animateOut="fadeOut"
        touchDrag={false}
        freeDrag={false}
        pullDrag={false}
        mouseDrag={false}
        onChanged={event => {
          // jQuery :(

          const item = event.item.index - 2 // Position of the current item
          $(".icon").removeClass("animated fadeInDown")
          $("h4").removeClass("animated fadeInUp")
          $("h1").removeClass("animated fadeInUp")
          $("p").removeClass("animated fadeInUp")
          $("a").removeClass("animated zoomIn")
          $(".owl-item")
            .not(".cloned")
            .eq(item)
            .find(".icon")
            .addClass("animated fadeInDown")
          $(".owl-item")
            .not(".cloned")
            .eq(item)
            .find("h4")
            .addClass("animated fadeInUp")
          $(".owl-item")
            .not(".cloned")
            .eq(item)
            .find("h1")
            .addClass("animated fadeInUp")
          $(".owl-item")
            .not(".cloned")
            .eq(item)
            .find("p")
            .addClass("animated fadeInUp")
          $(".owl-item")
            .not(".cloned")
            .eq(item)
            .find("a")
            .addClass("animated zoomIn")
        }}
      >
        <Item background={image}>
          <span className={cn(styles.icon, "flaticon-022-tray")} />
          <h4>Wszystkie drogi prowadzą do...</h4>
          <h1>Texasu</h1>
          <ButtonAnchor href="/#menu">
            <span>Nasze Menu</span>
          </ButtonAnchor>
        </Item>

        <Item background={image2}>
          <span className={cn(styles.icon, "flaticon-019-rib")} />
          <h4>
            Chuck Norris już wie <br />
            gdzie spędzi najbliższy weekend
          </h4>
          <h1>A Ty?</h1>
          <ButtonAnchor href="/#about">
            <span>O nas</span>
          </ButtonAnchor>
        </Item>
        <Item background={image}>
          <span className={cn(styles.icon, "flaticon-031-burrito")} />
          <h1>Zasmakuj w Texasie!</h1>
          <ButtonAnchor href="/#menu">
            <span>Nasze Menu</span>
          </ButtonAnchor>
        </Item>
        <Item background={image2}>
          <span className={cn(styles.icon, "flaticon-006-steak")} />
          <h1>Złap byka za rogi w Texasie!</h1>
          <ButtonAnchor href="/#menu">
            <span>Nasze Menu</span>
          </ButtonAnchor>
        </Item>
      </OwlCarousel>
    )}
  </header>
)

export default Slider
