import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { SubTitle } from "../components/sub-title"

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "_NAM8959.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section id="about" className="about pt-120 pb-120">
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-1 align-self-center d-flex flex-column">
            <SubTitle>Bar & Restaurant</SubTitle>
            <h4>
              Welcome to <span className="text-red">Texas</span>
            </h4>
            <p>
              Nazwa Texas pochodzi od indiańskiego słowa „tejas” oznaczającego
              „przyjaciół", a to właśnie idea stworzenia miejsca przyjaznego dla
              wszystkich zaowocowała otwarciem naszej restauracji. Gotowi na
              ucztę w iście kowbojskim stylu? Menu zostało skomponowane w taki
              sposób, aby zaintrygować naszych gości kompozycją dań rodzimych
              oraz kulinarnych inspiracji rodem ze słonecznego południa Stanów
              Zjednoczonych.
            </p>
          </div>
          <div className="col-md-5 ">
            <div className="embed-responsive embed-responsive-16by9 mb-20">
              <iframe
                width="1280"
                height="720"
                src="https://www.youtube.com/embed/ugVepwhkHkY"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className="about-img">
              <div className="img">
                <Img fluid={data.placeholderImage.childImageSharp.fluid} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
