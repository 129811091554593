import React from "react"
import { SubTitle } from "../components/sub-title"
import { ButtonAnchor } from "../components/button"
import * as cn from "classnames"

import * as styles from "./banner.module.scss"
import { Title } from "../components/title"
import { PHONE_NUMBER } from "../consts/contact-data"

const Banner = () => (
  <div className={styles.banner}>
    <div className={cn(styles.container, "container")}>
      <div className="row">
        <div className="col-md-12">
          <SubTitle>Rezerwacje</SubTitle>
          <Title>Zamówienia na dowóz</Title>
          <p>PONIEDZIAŁEK - SOBOTA : 12:00 - 20:00</p>
          <ButtonAnchor href="/#contact">
            <span>
              <i className="ti-phone" /> {PHONE_NUMBER}
            </span>
          </ButtonAnchor>
        </div>
      </div>
    </div>
  </div>
)

export default Banner
