import React, { useLayoutEffect } from "react"
import * as cn from "classnames"
import { SubTitle, SubTitleColors } from "../components/sub-title"
import { Title } from "../components/title"

import * as styles from "./orderOnline.module.scss"

const OrderOnline = () => {
  useLayoutEffect(() => {
    const script = document.createElement("script")

    script.src = "https://texas-bar-restaurant.skubacz.pl/menu_widget.js"
    script.async = true

    document.body.appendChild(script)
  }, [])

  return (
    <section id="order" className={cn(styles.container, "pt-120")}>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1 mb-40 text-center">
            <SubTitle color={SubTitleColors.red}>Dowóz & Wynos</SubTitle>
            <Title>Zamów online</Title>
          </div>
        </div>
      </div>
      <div id="restaumatic_menu_widget_wrapper">
        <iframe
          id="skubacz_menu_widget"
          src="https://texas-bar-restaurant.skubacz.pl/restauracje"
          width="100%"
          title="Menu"
        ></iframe>
      </div>
    </section>
  )
}

export default OrderOnline
