import React from "react"
import { SRLWrapper } from "simple-react-lightbox"
import { Title } from "../components/title"
import { SubTitle, SubTitleColors } from "../components/sub-title"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import * as cn from "classnames"

import * as styles from "./gallery.module.scss"
import { Button } from "../components/button"

const Gallery = () => {
  const data = useStaticQuery(graphql`
    query {
      thumbnails: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "gallery" }
        }
        sort: { fields: base, order: ASC }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid(maxWidth: 300, maxHeight: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      images: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "gallery" }
        }
        sort: { fields: base, order: ASC }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const options = {
    overlayColor: "rgba(15,15,15,0.95)",
    buttonsBackgroundColor: "transparent",
    buttonsIconColor: "#852D19",
    showCaption: true,
    showDownloadButton: false,
    showThumbnails: true,
    autoplaySpeed: 1500,
    enablePanzoom: false,
  }

  return (
    <section id="gallery" className="portfolio pt-120 pos-re pb-120">
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1 mb-40 text-center">
            <SubTitle color={SubTitleColors.red}>Texas</SubTitle>
            <Title>Galeria</Title>
          </div>
        </div>
        <div className="gallery text-center full-width">
          <SRLWrapper options={options}>
            <div className="row">
              {data.thumbnails.edges.map((img, index) => {
                const ref = React.createRef()
                return (
                  <div key={img.node.base} className={cn("col-md-3")}>
                    <div className="item-img">
                      <a
                        href={
                          data.images.edges[index].node.childImageSharp.fluid
                            .src
                        }
                        data-attribute="SRL"
                      >
                        <img
                          src={
                            data.images.edges[index].node.childImageSharp.fluid
                              .src
                          }
                          alt=""
                          style={{ display: "none" }}
                          ref={ref}
                          lazy="true"
                        />
                      </a>

                      {index < 8 && (
                        <div
                          className={styles.itemImg}
                          onClick={() => ref.current.click()}
                        >
                          <Img
                            fluid={img.node.childImageSharp.fluid}
                            loading="lazy"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          </SRLWrapper>
        </div>
      </div>
    </section>
  )
}

export default Gallery
