import React from "react"

import "../styles/style.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from "./slider"
import About from "./about"
import Menu from "./menu"
import Gallery from "./gallery"
import Contact from "./contact"
import Banner from "./banner"
import OrderOnline from "./orderOnline"
import { graphql, useStaticQuery } from "gatsby"

export const IndexPage = () => {
  const imageQuery = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "menu.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="Texas Bar & Restaurant"
        image={imageQuery.placeholderImage.childImageSharp.fluid.src}
      />
      <Slider />
      <OrderOnline />
      <About />
      <Gallery />
      <Banner />
      <Contact />
    </Layout>
  )
}

export default IndexPage
