import React, { useEffect, useState } from "react"
import * as cn from "classnames"
import * as $ from "jquery"

import styles from "./menu.module.scss"
import { SubTitle } from "../components/sub-title"

import OwlCarousel from "react-owl-carousel"
import "owl.carousel/dist/assets/owl.carousel.css"
import "owl.carousel/dist/assets/owl.theme.default.css"

import data from "./menu.json"
import { Title } from "../components/title"

const firstElem = data[0].categoryName

const MenuCategory = ({ children, icon, onClick, id, index }) => (
  <div
    id={id.replace(/\s/g, "")}
    className={cn(styles.item)}
    onClick={onClick}
    role="button"
    tabIndex={index}
  >
    <span className={cn(styles.icon, icon)} />
    <h6>{children}</h6>
  </div>
)

const MenuItem = ({ itemName, price, description, size, hit }) => (
  <div className={cn(styles.menuInfo, "animated flipInX")}>
    <h5>
      <span>{itemName}</span>
      {hit && <sup>HIT</sup>}
      <span className={styles.price}>{price} PLN</span>
    </h5>
    {size && <small>{size}</small>}
    <p>{description}</p>
  </div>
)

const MenusContent = ({ activeItem }) => {
  const category = data.find(e => e.categoryName === activeItem)

  return (
    <div className="menus-content col-md-12">
      <div id="tab-1-content" className="cont active">
        <div className="row">
          <div className="col-md-5">
            {category.items
              .filter((_, i) => i % 2 === 0)
              .map(({ itemName, price, description, size, hit }) => (
                <MenuItem
                  key={itemName.replace(/\s/g, "".concat(price))}
                  size={size}
                  hit={hit}
                  itemName={itemName}
                  price={price}
                  description={description}
                />
              ))}
          </div>
          <div className="col-md-5 offset-md-2">
            {category.items
              .filter((_, i) => i % 2 === 1)
              .map(({ itemName, price, description, size, hit }) => (
                <MenuItem
                  key={itemName.replace(/\s/g, "".concat(price))}
                  size={size}
                  hit={hit}
                  itemName={itemName}
                  price={price}
                  description={description}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const Menu = () => {
  const [activeItem, setActiveItem] = useState(firstElem)
  const [elementIndex, setElementIndex] = useState(0)

  useEffect(() => {
    document
      .querySelectorAll(".".concat(styles.item))
      .forEach(e => $(e).removeClass(styles.active))
    $(`#${activeItem.replace(/\s/g, "")}`).addClass(styles.active)
  }, [activeItem])

  const categoryIndex = data.findIndex(e => e.categoryName === activeItem)

  return (
    <section id="menu" className={styles.section}>
      <div className={cn(styles.container, "container")}>
        <div className="row">
          <div className="col-md-10 offset-md-1 mb-40 text-center">
            <SubTitle>Bar & Restaurant</SubTitle>
            <Title>Menu na wynos</Title>
          </div>
        </div>
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="row">
              <div className="tabs-icon mb-40 col-md-10 offset-md-1 text-center">
                {typeof window !== "undefined" && OwlCarousel && (
                  <OwlCarousel
                    onDragged={e => setElementIndex(e.item.index)}
                    startPosition={elementIndex}
                    className="owl-theme"
                    margin={0}
                    autoplay={false}
                    dots={true}
                    nav
                    navText={[
                      `<span class="ti-shift-left-alt"></span>`,
                      `<span class="ti-shift-right-alt"></span>`,
                    ]}
                    responsiveClass
                    responsive={{
                      0: {
                        items: 2,
                      },
                      600: {
                        items: 2,
                      },
                      1000: {
                        items: 2,
                      },
                    }}
                  >
                    {data.map(({ categoryName, icon }, index) => (
                      <MenuCategory
                        index={index}
                        key={categoryName}
                        id={categoryName}
                        icon={icon}
                        onClick={(event) => {
                          event.stopPropagation();
                          event.nativeEvent.stopImmediatePropagation();
                          setActiveItem(categoryName);
                          return false;
                        }}
                      >
                        {categoryName}
                      </MenuCategory>
                    ))}
                  </OwlCarousel>
                )}
              </div>
              <MenusContent activeItem={activeItem} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Menu
