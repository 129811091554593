import React from "react"
import * as cn from "classnames"

import * as styles from "./sub-title.module.scss"

export const SubTitleColors = {
  red: styles.red,
  white: styles.white,
}

export const SubTitle = ({ children, color = SubTitleColors.white }) => (
  <h6 className={cn(styles.subTitle, color)}>{children}</h6>
)
